/* https://www.schemecolor.com/google-chrome-ui.php */

body {
  min-width: 100vw;
  min-height: 100vh;
}

.App {
  text-align: center;
}

.main {
  min-height: 100vh;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.over {
  position: absolute;
}

.untouchable {
  pointer-events: none;
}

.button {
  color: white;
  text-decoration: none;
  font-weight: 500;
  font-size: 50;
}
